import * as Ably from 'ably';
import { removeStorageData, setStorageData } from "../../../packages/framework/src/Utilities";
import * as Yup from 'yup';
import { getMessaging, getToken } from 'firebase/messaging'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { CountryData } from 'react-phone-input-2';
import { City, Country, ICity, ICountry } from 'country-state-city';
import { runEngine } from '../../../packages/framework/src/RunEngine';

export const configJSON = require("../../framework/src/config");

export const getLoginToken = () => {
    return localStorage.getItem("authToken");
};

export const getUserRole = () => {
    return localStorage.getItem("userRole");
};

export const getUserId = () => {
    return localStorage.getItem("userID");
}

export const callAfterTimer = (callback: any, timeout: number) => {
    setTimeout(() => {
        callback()
    }, timeout);
}
export const debounce = (func: (...args: any) => void, wait: number) => {
    let timeout: any;
    return function executedFunction(...args: any) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

export const isLoggedIn = () => !!localStorage.getItem("authToken")

export const replaceNullWithEmptyString = (obj: any) => {
    Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') {
            replaceNullWithEmptyString(obj[key]);
        } else if (obj[key] === null) {
            obj[key] = '';
        }
    });
    return obj;
};

export const client = new Ably.Realtime({ key: 'W5_OUA.cDKURw:voT3_JNTi6vFzatY7ceWH7JVsXXbLD8n79PODfu61e8' });
export const channel = client.channels.get('work-centivo');
export const getLocation = (): Promise<{ coords: { latitude: number; longitude: number; } }> => {
    return fetch("http://ip-api.com/json/?fields=status,message,lat,lon")
        .then(res => res.json())
        .then((res) => {
            if (res.status === "success") {
                const position = {
                    coords: {
                        latitude: res.lat,
                        longitude: res.lon
                    }
                };
                const coords = JSON.stringify({ latitude: res.lat, longitude: res.lon });
                setStorageData("coords", coords);
                return position;
            } else {
                throw new Error(res.message || "Failed to fetch location data");
            }
        })
        .catch(error => {
            removeStorageData("coords");
            return Promise.reject(error);
        });
};


export const downloadFile = (data: any, fileName: string, urlLink?: string) => {
    const blob = new Blob([data], { type: "octect/stream" });
    const url = URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.setAttribute("href", urlLink ? urlLink : url);
    anchor.setAttribute("download", fileName);
    anchor.setAttribute("target", "_blank");
    anchor.click();

    URL.revokeObjectURL(urlLink ? urlLink : url);
}

export const openTermsConditionLink = () => {
    window.open(window.location.origin + "/TermsConditionsDetail", '_blank', 'noopener,noreferrer');
}

export const handleNavigation = (path: string) => {
    window.location.href = path;
}


export const PasswordScema = Yup.string()
.required('Password is required')
.min(8, 'Password must be at least 8 characters long')
.matches(/[a-z]/, 'Password must contain at least one lowercase letter')
.matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
.matches(/\d/, 'Password must contain at least one numeric character')
.matches(/[~`!@#$%^&*()\-_+={}[\]|\\;:"<>,./?]/, 'Password must contain at least one special character')
.test(
  'embedded-special',
  'Password must have a special character embedded in the middle',
  (value) => {
    if (!value) return false;
    const specialMatch = value.match(/[~`!@#$%^&*()\-_+={}[\]|\\;:"<>,./?]/);
    if (!specialMatch) return false;
    const specialIndex = specialMatch.index;
    return specialIndex !== 0 && specialIndex !== value.length - 1;
  }
)

const sendMessageThroughChannels = (channelName:string,fetchMessages: (name: any) => void) => {
    const messages:any=[]

    const channel = client.channels.get(channelName);

    const handleMessage = (message:any) => {
      fetchMessages(message)
      messages.push(message)
    };
    channel.subscribe(handleMessage);
  
    const sendMessageToAbly = (message:string) => {
      const channel = client.channels.get(channelName);
      channel.publish('message', message);
    };
  
    return { messages, sendMessageToAbly };
  };
  
  export default sendMessageThroughChannels;
  
export const handleCondition = <T extends string | number>(condition: boolean, truePart: T, falsePart: T): T => {
    return condition ? truePart : falsePart;
}
export const handleScroll = () => {
    setTimeout(() => {
        const targetButton = document.getElementById('target-button');
        targetButton!.scrollIntoView({ behavior: 'smooth', block: 'end' });
    });
}

export const handleConditionFunction = (condition: boolean, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart
}

export const handleTwoValues = (firstPart: any, secondPart: any) =>{
    return !!(firstPart && secondPart)
}

export const isProfileDetailsUpdated = () => {
    return localStorage.getItem("setProfile")=='true';
};

export const generateToken = async () => {
    const messaging = getMessaging()
    const registration = await navigator.serviceWorker.ready;
    const permission = await Notification.requestPermission()
    let token = ""

    runEngine.debugLog('React App envs', process.env);
    if (permission === "granted") {
        token = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_VAPID_KEY,
            serviceWorkerRegistration: registration
        })
    }
    return token
}

export const META_TAGS = `
private equity careers,
growth equity jobs,
venture capital opportunities, 
infrastructure investment positions,
real estate investment roles,
public and private credit openings, 
investor relations roles, 
corporate development jobs, 
breaking into buy-side, 
breaking into sell-side, 
investment banking careers, 
equity research positions, 
sales and trading opportunities, 
market analysis roles, 
career advancement, 
work-life balance, 
networking, 
mentorship,recruit private equity professionals,
hire growth equity analysts,
venture capital recruitment,
infrastructure investment job postings,
real estate investment vacancies,
public and private credit roles,
investor relations positions,
corporate development openings,
investment banking hires,
equity research recruitment,
sales and trading job postings,
market analysis vacancies,
breaking into buy-side candidates,
breaking into sell-side applicants,
career development opportunities,
work-life balance initiatives,
networking events,
mentorship programs`

export function callWithDelay(func: Function, ...args: any) {
    setTimeout(() => {
      func(...args);
    });
  }

export function isTwoDaysOrLessRemaining(dateString: string): boolean {
    const inputDate = new Date(dateString);
    const currentDate = new Date();

    const timeDifference = inputDate.getTime() - currentDate.getTime();
  
    const daysDifference = timeDifference / (1000 * 3600 * 24);
  
    return daysDifference <= 2;
  }

  export function scrollToClassName(className: string) {
    setTimeout(() => {
        document.querySelector(className)!.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    });
  }

  export const isPhoneValidOrNot = ({ phone, country } :{
    phone: string;
    country: CountryData | {};
}) =>  {
    try {
        const { countryCode: countryCodePhoneInput, dialCode } = country as CountryData;
        if (!countryCodePhoneInput || !dialCode) return true;
        const countryCode = countryCodePhoneInput.toUpperCase() as CountryCode;
        const phoneWithoutDialCode = phone.replace(dialCode, '');
        const phoneNumber = parsePhoneNumber(phoneWithoutDialCode, countryCode);
        return phoneNumber?.isValid();
    } catch (error) {
        return false;
    }
};

export const getIdFromParams: (path: string) => undefined | string = (path) => {
    return path
}

type FormValues = {
    [something: string]: string | File | [] | string[],
    full_phone_number: string,
    email: string,
    resume: string | File,
    cover_letter: string | File
}

export const handleSubmitApplyJobFunction: (
    data: FormValues,
    step: number,
    submitJobDetailHandler: (data: FormValues) => void,
    saveDraftJobApplyData: (data: { email: string, full_phone_number: string, resume: { url: string | File, name: string, size: number, selectId: string | number }[], cover_letter: { url: string | File, name: string, size: number, selectId: string | number }[] }) => void,
    handleNext: () => void,
    setsStep: (value: React.SetStateAction<number>) => void,
    setButtonTitle: (value: React.SetStateAction<string>) => void,
    email: string,
    phoneNumber:string,
    coverLetterList: { url: string | File, name: string, size: number, selectId: string | number }[],
    resumeList: { url: string | File, name: string, size: number, selectId: string | number }[],
    othersAnswer:  {[key: string]: string },
    questionsLength: number
) => void = (
    data,
    step,
    submitJobDetailHandler,
    saveDraftJobApplyData,
    handleNext,
    setsStep,
    setButtonTitle,
    email,
    phoneNumber,
    coverLetterList,
    resumeList,
    othersAnswer,
    questionsLength
) => {
        if (step === 3) {
            let values = { ...data }
            let newData = { email: email, full_phone_number: phoneNumber, cover_letter: coverLetterList, resume: resumeList };
            for (let otherQuestion in othersAnswer) {
                values[otherQuestion] = [...values[otherQuestion] as string[], othersAnswer[otherQuestion]]
            }
            submitJobDetailHandler(data)
            saveDraftJobApplyData(newData)
        } else if (step === 2) {
            setButtonTitle('Submit')
            handleNext()

        } else {
            let newData = { email: email, full_phone_number: phoneNumber, cover_letter: coverLetterList, resume: resumeList };
            saveDraftJobApplyData(newData)
            if (questionsLength === 0) {
                setButtonTitle('Submit')
                setsStep(3)
                return;
            }
            setButtonTitle('Next')
            handleNext();
        }

    }

export const getCommaSeparatedValue = (value: string) => {
    return (value && (parseFloat(value).toLocaleString() !== "NaN")) ? parseFloat(value).toLocaleString() :  ""
}

export const handleKeyboardScroll = (event: React.KeyboardEvent, elementId: string) => {
    const container = document.getElementById(elementId);
    if (container) {
        if (event.key === 'ArrowDown') {
            container.scrollBy(0, 50);
        } else if (event.key === 'ArrowUp') {
            container.scrollBy(0, -50);
        }
    }
}


export function setEditDataCity(callback: (cities: { value: string, label: string }[]) => void,  countryX: string) {
    let allCountry: ICountry[] = Country.getAllCountries() ?? [];
    let CountryName: undefined | ICountry= allCountry.find((country: ICountry) => country.name.toLocaleLowerCase() === countryX.toLocaleLowerCase());
    if(CountryName) {
      callback(City.getCitiesOfCountry(CountryName.isoCode)?.map((city: ICity) => ({value: city.name, label: city.name})) ?? [])
    }
}

export function getOrdinalSuffix(number: number) {
    const remainderOf10 = number % 10;
    const remainderOf100 = number % 100;

    if(!number){
        return "-"
    }

    if (remainderOf100 >= 11 && remainderOf100 <= 13) {
      return `${number}th`;
    }
  
    switch (remainderOf10) {
      case 1:
        return `${number}st`;
      case 2:
        return `${number}nd`;
      case 3:
        return `${number}rd`;
      default:
        return `${number}th`;
    }
  }

export function getNameFirstLatters (value: string | undefined) {

    if(!value) {
        return ""
    }
    let ans = ""
    
    if(value.split(" ")[0]) {
        ans += value.split(" ")[0][0]
    }

    if(value.split(" ")?.[1]) {
        ans += value.split(" ")[1][0]
    } else {
        ans += value.split(" ")[0][1]
    }
    return ans
}

export let convertToK = (number: number) =>  {
    if (number >= 1000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
    }
    return number.toString();
  }
  
